module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.heubach.com/graphql","schema":{"perPage":100,"requestConcurrency":4,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"type":{"Applications":{"excludeFieldNames":["contentNodes","products"]},"Industries":{"excludeFieldNames":["wpChildren","contentNodes","products"]},"Function":{"excludeFieldNames":["wpChildren","contentNodes","products"]},"Comment":{"exclude":true},"Category":{"exclude":true},"GfForm":{"excludeFieldNames":["entries"]},"GfEntry":{"exclude":true},"GfSubmittedEntry":{"exclude":true},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"UserRole":{"exclude":true},"Tag":{"exclude":true},"PostFormat":{"exclude":true},"User":{"exclude":true},"RegionalOffice":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gravity-forms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.heubach.com/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/icon.png","name":"Heubach Group","short_name":"Heubach","start_url":"/","background_color":"#0d6562","theme_color":"#0d6562","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cf9df8ea04cefec3fc4ee03ce76af7b9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.heubach.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
